import { getSummary, prepareConsultantData } from '../utils/utils';

const EffectiveReportConsultant = (props) => {
	const displayData = prepareConsultantData(props);
	displayData.push(getSummary(displayData));

	return (
		<div style={{ width: '80vw' }}>
			<div style={{ marginBottom: '40px' }}>
				<table className='styled-table styled-table-summary'>
					<thead>
						<tr>
							<th>Consultant</th>
							<th>Market</th>
							<th>Product</th>
							{props.weights && <th>Download no</th>}
							<th>COD number</th>
							<th>COD sum</th>
							<th>COD delivered number</th>
							<th>COD delivered sum</th>
							<th>WPPK</th>
							<th>WPPK delivered</th>
							<th>Number of downloaded orders</th>
							<th>Shifts</th>
							<th>Confirmed</th>
							<th>No_response</th>
							<th>Informed</th>
							<th>Trash</th>
							<th>Resigned</th>
							<th>Weight sum</th>
						</tr>
					</thead>
					<tbody className='queue'>
						{displayData.map((row, index) => (
							<tr key={index}>
								<td>{row.consultant || '-'}</td>
								<td>{row.market || '-'}</td>
								<td>{row.products || '-'}</td>
								{props.weights && <td>{row.downloadNo}</td>}
								<td>{row.codNo}</td>
								<td>{row.codSum}</td>
								<td>{row.codDeliveredNo}</td>
								<td>{row.codDeliveredSum}</td>
								<td>{row.wppk.toFixed(2)}</td>
								<td>{row.wppkDelivered.toFixed(2)}</td>
								<td>{row.noDownloadedOrders}</td>
								<td>{row.shifts}</td>
								<td>{row.confirmed}</td>
								<td>{row.noResponse}</td>
								<td>{row.informed}</td>
								<td>{row.trash}</td>
								<td>{row.resigned}</td>
								<td>{row.weightSum.toFixed(2)}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default EffectiveReportConsultant;
