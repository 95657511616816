export const prepareConsultantData = ({
	weights,
	products: productList,
	markets: marketList,
	users: userList,
	data,
}) => {
	const displayData = [];

	data
		.sort((a, b) => comparer(a, b, weights))
		.forEach((row) => {
			const consultantComparer = weights ? row._id.split('-')[0] : row._id;
			const consultant = userList.find(
				(a) => a.value === consultantComparer
			).label;
			const market = marketList.find((a) => a.value === row.market).label;
			const products = productList.find((a) => a.value === row.product).label;
			const downloadNo = weights ? parseInt(row._id.split('-')[1]) : null;
			const wppk =
				row.weight_all_sum > 0
					? Math.round(parseFloat(row.cod_sum / row.weight_all_sum) * 100) / 100
					: 0;
			const wppkDelivered =
				row.weight_all_sum > 0
					? Math.round(
							parseFloat(row.cod_delivered_sum / row.weight_all_sum) * 100
					  ) / 100
					: 0;
			const weightSum = Math.round(parseFloat(row.weight_all_sum) * 100) / 100;

			displayData.push({
				id: row._id,
				consultant,
				market,
				products,
				downloadNo,
				codNo: row.cod_count,
				codSum: Math.round(row.cod_sum),
				codDeliveredNo: row.cod_delivered_count,
				codDeliveredSum: row.cod_delivered_sum,
				wppk,
				wppkDelivered,
				noDownloadedOrders: row.dequeue_count,
				shifts: row.shift,
				confirmed: row.confirmed,
				noResponse: row.no_response,
				informed: row.informed,
				trash: row.trash,
				resigned: row.resigned,
				weightSum,
			});
		});

	return displayData;
};

export const prepareMarketData = ({
	weights,
	products: productList,
	markets: marketList,
	data,
}) => {
	const displayData = [];

	data
		.sort((a, b) => comparer(a, b, weights))
		.forEach((row) => {
			const market = marketList.find((a) => a.value === row.market).label;
			const products = productList.find((a) => a.value === row.product).label;
			const downloadNo = weights ? parseInt(row._id.split('-')[1]) : null;
			const wppk =
				row.weight_all_sum > 0
					? Math.round(parseFloat(row.cod_sum / row.weight_all_sum) * 100) / 100
					: 0;
			const wppkDelivered =
				row.weight_all_sum > 0
					? Math.round(
							parseFloat(row.cod_delivered_sum / row.weight_all_sum) * 100
					  ) / 100
					: 0;
			const weightSum = Math.round(parseFloat(row.weight_all_sum) * 100) / 100;

			displayData.push({
				id: row._id,
				market,
				products,
				downloadNo,
				codNo: row.cod_count,
				codSum: Math.round(row.cod_sum),
				codDeliveredNo: row.cod_delivered_count,
				codDeliveredSum: row.cod_delivered_sum,
				wppk,
				wppkDelivered,
				inbounds: row.inbounds,
				outbounds: row.outbounds,
				contacts: row.contacts,
				weightSum,
			});
		});

	return displayData;
};

function comparer(a, b, weights) {
	if (weights) {
		const [aId, aDownloadNo] = a._id.split('-');
		const [bId, bDownloadNo] = b._id.split('-');

		const idCompareResult = aId.localeCompare(bId);
		if (idCompareResult !== 0) {
			return idCompareResult;
		}

		return parseInt(aDownloadNo) - parseInt(bDownloadNo);
	}

	return a._id.localeCompare(b._id);
}

export const getSummary = (data) => {
	const summary = {};

	data.forEach((row) => {
		Object.keys(row).forEach((propertyName) => {
			if (typeof row[propertyName] === 'number') {
				if (!summary[propertyName]) summary[propertyName] = row[propertyName];
				else summary[propertyName] += row[propertyName];
			}
		});
	});

	return summary;
};
