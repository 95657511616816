import moment from 'moment';
import styled from 'styled-components';
import { Colors } from '../colors/colors';

const Flex = styled.div`
	display: flex;
	flex-direction: ${(props) => props.direction};
	align-items: ${(props) => props.alignItems};
	margin: ${(props) => props.margin};
	gap: ${(props) => props.gap};

	label {
		width: fit-content;
	}
	input {
		width: fit-content;
		padding: 5px;
		outline: none;

		&::placeholder {
			color: ${(props) => props.placeholderColor};
		}
	}
`;

const Input = ({
	placeholderColor = Colors.darkGray2,
	inputRef,
	value,
	name = true,
	required,
	disabled = false,
	type = 'text',
	width = 300,
	height = 0,
	onChange,
	inputWidth = 260,
	inputName,
	fontSize = '18px',
	min = -100,
	max = Infinity,
	step,
	checked,
	isChecked,
	requiredSign,
	placeholder = 'type...',
	paddingLeft = '10px',
	showLabel = true,
	color = 'black',
	controlled,
	gap = '30px',
	id,
	link,
	onKeyPress = () => {},
	margin = '10px',
	style = {},
	accept = null,
	pattern,
	direction = 'row',
	noColon = false,
	inputFontSize = '16px',
	alignItems = 'center',
	autoFocus = false,
	hasIncDec = false,
}) => {
	const incDec = (value) => {
		const date = new Date(inputRef.current.value);
		date.setDate(date.getDate() + value);
		inputRef.current.value = moment(date).format('YYYY-MM-DD');
	};

	return (
		<Flex
			placeholderColor={placeholderColor}
			alignItems={alignItems}
			direction={direction}
			gap={gap}
			margin={margin}
		>
			{showLabel && (
				<label
					className={`${link && 'animation-scale'}`}
					htmlFor={id}
					style={{
						width: `${width}px`,
						textAlign: 'right',
						fontSize: fontSize,
						cursor: `${(type === 'checkbox' && id) || link ? 'pointer' : null}`,
						...style,
					}}
					onClick={() => {
						if (link) {
							window.open(link, '_blank');
						}
					}}
				>
					<strong style={{ color: color }}>
						{name}
						{!noColon && ':'}{' '}
						{requiredSign ? <span style={{ color: Colors.red }}>*</span> : ''}
					</strong>
				</label>
			)}
			<div className='inc-dec-wrapper'>
				<input
					pattern={pattern}
					accept={accept}
					autoFocus={autoFocus}
					name={inputName || id}
					min={min}
					max={max}
					id={id}
					type={type}
					ref={inputRef}
					required={required}
					disabled={disabled}
					{...(!controlled && { defaultValue: value })}
					{...(controlled && { value: value || '' })}
					onChange={onChange}
					placeholder={placeholder}
					step='any'
					defaultChecked={checked}
					checked={isChecked}
					style={{
						paddingLeft: '10px',
						width: `${inputWidth}px`,
						height: `${height ? height : null}px`,
						fontSize: inputFontSize,
					}}
					onKeyPress={onKeyPress}
				/>
				{hasIncDec && (
					<>
						<i className='bi bi-dash inc-dec' onClick={() => incDec(-1)}></i>
						<i className='bi bi-plus inc-dec' onClick={() => incDec(+1)}></i>
					</>
				)}
			</div>
		</Flex>
	);
};

export default Input;
