import { getSummary, prepareMarketData } from '../utils/utils';

const EffectiveReportMarket = (props) => {
	const displayData = prepareMarketData(props);
	displayData.push(getSummary(displayData));

	return (
		<div style={{ width: '80vw' }}>
			<div
				style={{
					marginBottom: '40px',
					justifyContent: 'center',
					display: 'flex',
				}}
			>
				<table className='styled-table styled-table-summary'>
					<thead>
						<tr>
							<th>Market</th>
							<th>Product</th>
							{props.weights && <th>Download no</th>}
							<th>COD number</th>
							<th>COD sum</th>
							<th>COD delivered number</th>
							<th>COD delivered sum</th>
							<th>WPPK</th>
							<th>WPPK delivered</th>
							<th>Inbounds</th>
							<th>Outbounds</th>
							<th>Contacts</th>
							<th>Weight sum</th>
						</tr>
					</thead>
					<tbody className='queue'>
						{displayData.map((row, index) => (
							<tr key={index}>
								<td>{row.market || '-'}</td>
								<td>{row.products || '-'}</td>
								{props.weights && <td>{row.downloadNo}</td>}
								<td>{row.codNo}</td>
								<td>{row.codSum}</td>
								<td>{row.codDeliveredNo}</td>
								<td>{row.codDeliveredSum}</td>
								<td>{row.wppk.toFixed(2)}</td>
								<td>{row.wppkDelivered.toFixed(2)}</td>
								<td>{row.inbounds}</td>
								<td>{row.outbounds}</td>
								<td>{row.contacts}</td>
								<td>{row.weightSum.toFixed(2)}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default EffectiveReportMarket;
