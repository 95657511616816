export const MARKET_TO_CURRENCY = [
  {
    label: "czk",
    value: "cz",
  },
  {
    label: "pln",
    value: "pl",
  },
  {
    label: "at",
    value: "eur",
  },
  {
    label: "sk",
    value: "eur",
  },
  {
    label: "hu",
    value: "huf",
  },
  {
    label: "bg",
    value: "bgn",
  },
];

export const CURRENCY = [
  { label: "pln", value: "pln" },
  { label: "czk", value: "czk" },
  { label: "eur", value: "eur" },
  { label: "ron", value: "ron" },
  { label: "huf", value: "huf" },
  { label: "bgn", value: "bgn" },
];

export const LANGUAGES = [
  { label: "Polski", value: "pl" },
  { label: "English", value: "en" },
  { label: "Český", value: "cs" },
  { label: "Slovenská", value: "sk" },
  { label: "Românesc", value: "ro" },
];
