import { getProductsWithCouriers } from "@/API/repositories/lms";
import { Colors } from "@/common/colors/colors";
import ActionButton from "@/common/components/buttons/ActionButton";
import SelectInput from "@/common/components/SelectInput";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { useTranslationContext } from "@/common/hooks/useTranslationContext";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
	markOrdertAsConsultantDecision,
	sendShipping,
} from "../../../../API/repositories/order";
import userManager from "../../../../API/userManager";
import Loading from "../../../../common/components/Loading";
import { useRequestsContext } from "../../../../common/hooks/requestHook";

const Flex = styled.div`
  margin: 20px 0px 0px 0px;
  display: flex;
  justify-content: right;
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 90px;
`;

const ConfirmationView = ({
  product,
  shippingData,
  chosenProductData,
  orderId,
  setCallingClientCredentials,
  setCurrentContactOutbound,
  setShowCallingDescription,
}) => {
  const [selectedCourier, setSelectedCourier] = useState();
  const [courierOptions, setCourierOptions] = useState();
  const [productsWithCouriers, setProductsWithCouriers] = useState();

  const { COUNTRIES } = useCommonDataContext();
  const { translation } = useTranslationContext();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const today = moment().add(3, "days").format("YYYY-MM-DD");
  const suggestedDeliveryDateRef = useRef();

  const loadCouriers = async () => {
    setSelectedCourier(() => null);
    setCourierOptions(() => []);

    const response = await makeRequest(getProductsWithCouriers);

    if (response?.data) {
      setProductsWithCouriers(response.data);
    }
  };

  const handleSetCouriersData = () => {
    if (!productsWithCouriers) {
      return;
    }

    const currentProductWithCourier = productsWithCouriers.find(
      (productWithCourier) => product.short === productWithCourier.short
    );

    if (!currentProductWithCourier) {
      return;
    }

    const postsMap = new Map();

    const allPosts = [
      ...currentProductWithCourier.default_posts,
      ...currentProductWithCourier.posts,
    ];

    allPosts.forEach((post) => {
      postsMap.set(post.name, post._id);
    });

    const options = Array.from(postsMap).map(([label, value]) => ({
      label,
      value,
    }));

    setCourierOptions(options);

    setSelectedCourier(options[0]);
  };

  const handleSendShipping = async (e) => {
    e.preventDefault();

    const deliveryDate = suggestedDeliveryDateRef.current
      ? moment(suggestedDeliveryDateRef.current.value).toDate()
      : moment().add(3, "days").toDate();
    const curier_lms_id = selectedCourier?.value;

    const payload = {};

    payload.suggested_delivery_date = deliveryDate;
    payload.curier_lms_id = curier_lms_id;

    const response = await makeRequest(
      sendShipping.bind(null, orderId, payload)
    );

    if (response.data) {
      const responseDecision = await makeRequest(
        markOrdertAsConsultantDecision.bind(
          this,
          userManager.getUser().id,
          orderId,
          { type: "CONFIRM", additionalInformation: {} }
        )
      );
      setCallingClientCredentials(() => null);
      setCurrentContactOutbound(() => null);
      setShowCallingDescription(() => true);
    } else {
      // addMessage(response.error, "error");
    }
  };

  useEffect(() => {
    loadCouriers();
  }, []);

  useEffect(() => {
    handleSetCouriersData();
  }, [productsWithCouriers]);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            gap: "30px",
          }}
        >
          <h4>
            <u>{translation.product}:</u> {product.name}{" "}
          </h4>
          <div>
            <h4 style={{ marginBottom: "30px" }}>
              <u>{translation.items}:</u>
            </h4>
            <table style={{ marginLeft: " 30px" }}>
              <thead>
                <tr>
                  <th style={{ fontSize: "20px", textAlign: "center" }}>
                    {translation.variant}
                  </th>
                  <th style={{ fontSize: "20px", textAlign: "center" }}>
                    {translation.short}
                  </th>
                  <th style={{ fontSize: "20px", textAlign: "center" }}>
                    {translation.price}
                  </th>
                  <th style={{ fontSize: "20px", textAlign: "center" }}></th>
                </tr>
              </thead>
              <tbody>
                {chosenProductData &&
                  chosenProductData.map((data) => (
                    <tr>
                      <td
                        style={{
                          width: "100px",
                          fontSize: "18px",
                          borderTop: "1px solid grey",
                          textAlign: "center",
                          padding: "11px 0px",
                        }}
                      >
                        {data.name}
                      </td>
                      <td
                        style={{
                          width: "200px",
                          fontSize: "18px",
                          borderTop: "1px solid grey",
                          textAlign: "center",
                        }}
                      >
                        {data.value}
                      </td>
                      <td
                        style={{
                          width: "100px",
                          fontSize: "18px",
                          borderTop: "1px solid grey",
                          textAlign: "center",
                        }}
                      >
                        {data.price} {}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div
          style={{
            width: "600px",
          }}
        >
          <h4>
            <u>{translation.shippingInfo}:</u>
          </h4>
          <div
            style={{
              marginTop: "20px",
              width: "400px",
              display: "flex",
              gap: " 5px",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", gap: "20px" }}>
              <span style={{ width: "130px", textAlign: "right" }}>
                {translation.fullName}:
              </span>{" "}
              <div>{shippingData.full_name}</div>
            </div>
            <div style={{ display: "flex", gap: "20px" }}>
              <span style={{ width: "130px", textAlign: "right" }}>Email:</span>{" "}
              <div>{shippingData.email}</div>
            </div>
            <div style={{ display: "flex", gap: "20px" }}>
              <span style={{ width: "130px", textAlign: "right" }}>
                {translation.street}:
              </span>{" "}
              <div> {shippingData.street}</div>
            </div>
            <div style={{ display: "flex", gap: "20px" }}>
              <span style={{ width: "130px", textAlign: "right" }}>
                {translation.postalCode}:
              </span>{" "}
              <div> {shippingData.postal_code}</div>
            </div>
            <div style={{ display: "flex", gap: "20px" }}>
              <span style={{ width: "130px", textAlign: "right" }}>
                {translation.country}:
              </span>{" "}
              <div>
                {
                  COUNTRIES.find(
                    (country) => shippingData.country === country.value
                  ).label
                }
              </div>
            </div>
            <div style={{ display: "flex", gap: "20px" }}>
              <span style={{ width: "130px", textAlign: "right" }}>
                {translation.countryCode}:
              </span>{" "}
              <div>{shippingData.country}</div>
            </div>
            <div style={{ display: "flex", gap: "20px" }}>
              <span style={{ width: "130px", textAlign: "right" }}>
                {translation.phone}:
              </span>{" "}
              <div>{shippingData.phone_number}</div>
            </div>
            <div style={{ display: "flex", gap: "20px" }}>
              <span style={{ width: "130px", textAlign: "right" }}>
                {translation.city}:
              </span>{" "}
              <div> {shippingData.city}</div>
            </div>
            <div style={{ display: "flex", gap: "20px" }}>
              <span style={{ width: "130px", textAlign: "right" }}>
                {translation.courierInfo}:
              </span>{" "}
              <div> {shippingData.additional_info}</div>
            </div>
          </div>
        </div>
      </div>
      <Flex>
        <form>
          <ActionWrapper>
            <SelectInput
              selected={selectedCourier}
              setSelected={setSelectedCourier}
              options={courierOptions}
              name={translation.courier}
              width={150}
            />
            <label>{translation.deliveryDate}:</label>
            <input
              ref={suggestedDeliveryDateRef}
              type="date"
              min={today}
              defaultValue={today}
              required
              style={{ width: "150px" }}
            />
            <ActionButton
              color={Colors.green}
              onClick={(e) => handleSendShipping(e)}
              defaultText={translation.send}
            />
          </ActionWrapper>
        </form>
      </Flex>
      {hasUnfilledRequest(sendShipping, getProductsWithCouriers) && <Loading />}
    </div>
  );
};

export default ConfirmationView;
