import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import {
  acceptConsultantMonthHours,
  getHoursForCCManager,
  saveConsultantHoursByManager,
} from "@/API/repositories/consultantHours";

import { useRequestsContext } from "@/common/hooks/requestHook";
import { CONSULTANT_ROLES } from "@/pages/login/components/LoginForm/loginForm";
import Loading from "@/common/components/Loading";
import { saveAs } from "@/common/functions/saveAs";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { LAST_YEAR_MONTHS } from "@/common/constants/lastYearMonths";
import { TableWrapper, Wrapper } from "./CCHours.styled";
import ConsultantMonth from "./components/consultantMonth/ConsultantMonth";
import AddHoursForAny from "./components/consultantMonth/components/addHoursForAny/AddHoursForAny";
import { getPdfsByIds } from "@/API/repositories/storedDocument";
import { COMPANIES_OPTIONS } from "@/common/constants/Accounting";
import {
  extractConsultantIds,
  filterMonthHourConsultants,
} from "@/common/functions/consultantMonthFilesHelpers";
import HoursTable from "@/common/components/hoursTable/HoursTable";
import HoursSearchBar from "@/common/components/hoursSearchBar/HoursSearchBar";
import { getMarketById } from "../claimsReport/helpers/getMarketById";
import { findUserById } from "@/common/functions/findUserById";
import { getDocsJobs, triggerDocs } from "@/API/repositories/mailing-report";
import { useMessageQueueContext } from "@/common/hooks/useMessageQueue";
import { DOC_BLOCKERS } from "@/common/constants/docBlockers";
import { generateHoursReportFiles } from "@/common/functions/generateHoursReportFiles";
import { getHeadersByMarket } from "@/common/constants/headersHoursReport";
import { formatDate } from "@/common/functions/dateFormater";
import { blobToBase64 } from "@/common/functions/getBase64";
const JSZip = require("jszip");
let zip = new JSZip();

const CCHours = () => {
  const {
    filterUsersByRoles,
    commonData: { users, markets },
    options: { queuesOptions },
    queryValues: { isLoading },
  } = useCommonDataContext();

  const fullConsultantsCb = () => {
    return users.filter((user) => CONSULTANT_ROLES.includes(user.role));
  };

  const consultantsCb = () => {
    return filterUsersByRoles(CONSULTANT_ROLES);
  };

  const [selectedQueues, setSelectedQueues] = useState(queuesOptions);
  const [filterRegex, setFilterRegex] = useState();

  const [allData, setAllData] = useState();
  const [consultants, setConsultants] = useState(consultantsCb);
  const [consultantsFull, setConsultantFull] = useState(fullConsultantsCb);
  const [selectedConsultant, setSelectedConsultants] = useState(consultantsCb);
  const [isAddHoursForAnyOpen, setIsAddHoursForAnyOpen] = useState();
  const [selectedCompany, setSelectedCompany] = useState(COMPANIES_OPTIONS[0]);
  const [selectedMonth, setSelectedMonth] = useState(LAST_YEAR_MONTHS[0]);
  const [showConsultantMonth, setShowConsultantMonth] = useState(null);
  const [selectedMarket, setSelectedMarket] = useState();
  const [accepted, setAccepted] = useState(false);
  const [docsJobs, setDocsJobs] = useState();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueueContext();

  const data = useMemo(() => {
    if (selectedCompany && allData) {
      return [...allData].filter(
        (ad) => ad._user.company === selectedCompany.value
      );
    }

    return allData;
  }, [selectedCompany, allData]);

  const handleSelectMarket = (market) => {
    if (!market) {
      return setSelectedMarket(null);
    }

    setSelectedMarket(() => market);
    setSelectedQueues(null);
    const fullMarket = getMarketById(market.value, markets);
    setSelectedConsultants(() =>
      consultants.filter((c) => {
        const consultant = findUserById(c.value, users);

        const consultantMarketShort = consultant?.contract_type
          .split("_")[0]
          .toLowerCase();
        return fullMarket.short === consultantMarketShort;
      })
    );
  };

  const hanldeSearch = async (e, shouldFindSelected = false) => {
    e && e.preventDefault();
    setAllData(() => null);
    const payload = {};
    payload.month = selectedMonth.value.startOf("month");
    payload.monthFormated = moment(selectedMonth.value)
      .format("MMMM_YYYY")
      .toUpperCase();
    payload.accepted = accepted;

    const response = await makeRequest(
      getHoursForCCManager.bind(null, payload)
    );

    if (response.data) {
      setAllData(() => response.data);

      response.data.forEach((hours, i) => {
        const foundTicket = hours.data.some((hour) => hour._ticket);

        response.data[i].foundTicket = foundTicket;
      });

      if (shouldFindSelected) {
        setShowConsultantMonth(() =>
          response.data.find((d) => d._id === showConsultantMonth._id)
        );
      }

      loadDocsJobs();
    }
  };

  const handleSelectQueue = (queue) => {
    let selectedQueueUsers = [];
    queue.forEach((q) => {
      selectedQueueUsers = [...selectedQueueUsers, ...q.value.consultants];
    });
    const users = consultants.filter((c) =>
      selectedQueueUsers.includes(c.value)
    );
    setSelectedConsultants(() => users);
    setSelectedQueues(() => queue);
    setSelectedMarket(null);
  };

  const handleGetAllMonthFiles = async () => {
    if (!data) {
      return;
    }

    const selectedConsultantsIds = selectedConsultant.map((c) => c.value);
    const ids = extractConsultantIds(
      filterMonthHourConsultants(data, filterRegex, selectedConsultantsIds)
    );
    const query = { ids };

    const response = await makeRequest(getPdfsByIds.bind(null, query));
    if (response.data) {
      const files = response.data;

      files.forEach((file) => {
        zip.file(file.name, file.data.data);
      });

      zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(
          content,
          `documents_${selectedMonth.label
            .split(" ")
            .join("_")
            .toLowerCase()}.zip`
        );
      });

      zip = new JSZip();
    }
  };

  const handleTriggerDocs = async () => {
    if (!selectedMarket?.value || !accepted) {
      return addMessage("Please select market and accepted", "error");
    }

    const market = getMarketById(selectedMarket.value, markets);

    const files = await generateHoursReportFiles({
      data,
      selectedConsultant,
      consultants,
      workingFromDate: selectedMonth.value.startOf("month"),
      workingToDate: selectedMonth.value.endOf("month"),
      filterRegex: market.short,
      selectedMonth,
      headers: getHeadersByMarket(market.short),
    });

    const selectedConsultantsIds = selectedConsultant.map((c) => c.value);
    const ids = extractConsultantIds(
      filterMonthHourConsultants(data, filterRegex, selectedConsultantsIds)
    );
    const query = { ids };

    if (ids?.length) {
      const filesResponse = await makeRequest(getPdfsByIds.bind(null, query));
      if (filesResponse.data) {
        const absenceFiles = filesResponse.data;

        absenceFiles.forEach((file) => {
          zip.file(file.name, file.data.data);
        });

        const content = await zip.generateAsync({ type: "blob" });

        files.push({
          name: `documents_${formatDate(
            selectedMonth.label,
            "MMMM_YYYY"
          ).toLowerCase()}.zip`,
          data: await blobToBase64(content),
        });
      }
      zip = new JSZip();
    }

    const payload = {
      month: moment(selectedMonth.value).format("MM.YYYY"),
      market_short: market.short,
      unlock_blocker: DOC_BLOCKERS.HOURS,
      files,
    };

    const response = await makeRequest(triggerDocs.bind(null, payload));

    if (!response?.data) {
      return addMessage("Error while triggering docs", "error");
    }

    loadDocsJobs();
    addMessage("Docs triggered", "success");
  };

  const loadDocsJobs = async () => {
    const response = await makeRequest(getDocsJobs);

    if (response?.data) {
      setDocsJobs(() =>
        response.data?.reduce((acc, curr) => {
          acc[`${curr.payload.month}_${curr.payload.market_short}`] = curr;

          return acc;
        }, {})
      );
    }
  };

  const handleChangeRegex = (e) => {
    if (e.target.value.length >= 1) {
      return setFilterRegex(() => e.target.value);
    }

    return setFilterRegex(() => null);
  };

  const reportConfirmed = useMemo(() => {
    if (!selectedMarket) {
      return true;
    }

    const market = getMarketById(selectedMarket.value, markets);
    const docsJob =
      docsJobs?.[`${selectedMonth.value.format("MM.YYYY")}_${market.short}`];

    if (!docsJob) {
      return;
    }

    if (!docsJob?.blocker_condition?.hours_block) {
      return docsJob;
    }

    return null;
  }, [docsJobs, selectedMarket, selectedMonth]);

  useEffect(() => {
    if (!consultantsFull?.length) {
      setConsultants(consultantsCb(CONSULTANT_ROLES));
      setConsultantFull(fullConsultantsCb());
      setSelectedConsultants(consultantsCb(CONSULTANT_ROLES));
    }

    if (!selectedQueues?.length) {
      setSelectedQueues(queuesOptions);
    }
  }, [users, queuesOptions]);

  useEffect(() => {
    loadDocsJobs();
  }, [selectedMonth]);

  return (
    <Wrapper>
      {(hasUnfilledRequest(
        getHoursForCCManager,
        acceptConsultantMonthHours,
        saveConsultantHoursByManager,
        getPdfsByIds,
        getDocsJobs,
        triggerDocs
      ) ||
        isLoading) && <Loading />}
      <HoursSearchBar
        reportConfirmed={reportConfirmed}
        selectedCompany={selectedCompany}
        setSelectedCompany={setSelectedCompany}
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
        handleChangeRegex={handleChangeRegex}
        consultants={consultants}
        selectedConsultant={selectedConsultant}
        setSelectedConsultants={setSelectedConsultants}
        selectedQueues={selectedQueues}
        handleSelectQueue={handleSelectQueue}
        queuesOptions={queuesOptions}
        accepted={accepted}
        setAccepted={setAccepted}
        hanldeSearch={hanldeSearch}
        setIsAddHoursForAnyOpen={setIsAddHoursForAnyOpen}
        handleGetAllMonthFiles={handleGetAllMonthFiles}
        data={data}
        handleSelectMarket={handleSelectMarket}
        selectedMarket={selectedMarket}
        handleTriggerDocs={handleTriggerDocs}
      />
      {data &&
        consultants &&
        data.length > 0 &&
        selectedConsultant.length > 0 && (
          <TableWrapper>
            <HoursTable
              data={data}
              filterRegex={filterRegex}
              selectedConsultant={selectedConsultant}
              consultants={consultants}
              setShowConsultantMonth={setShowConsultantMonth}
              selectedMonth={selectedMonth}
              selectedCompany={selectedCompany}
              accepted={accepted}
            />
          </TableWrapper>
        )}
      {showConsultantMonth && (
        <ConsultantMonth
          selectedMonth={selectedMonth}
          showConsultantMonth={showConsultantMonth}
          setShowConsultantMonth={setShowConsultantMonth}
          consultants={consultants}
          consultantsFull={consultantsFull}
          hanldeSearch={hanldeSearch}
        />
      )}
      {isAddHoursForAnyOpen && (
        <AddHoursForAny
          monthName={selectedMonth.value}
          setIsAddHoursForAnyOpen={setIsAddHoursForAnyOpen}
          hanldeSearch={hanldeSearch}
        />
      )}
    </Wrapper>
  );
};

export default CCHours;
